import Supercluster from "supercluster";
import { maxZoom } from "../configs/configs";
import L from "leaflet";
import { makeMarker } from "./markers";
import { ReadByBoundData } from "./postgis";
import { parsePopupContent } from "../utils/utils";

// Initialize the supercluster.
// It is out of the useMarkers function because it should be initialized only once and is outside of the lifecycle of the component.
const superClusterIndex = new Supercluster({
  radius: 100,
  maxZoom,
  minPoints: 5,
});

export class Cluster {
  // Properties
  private _map: L.Map;
  private _clusterLayer: L.GeoJSON;
  private _markerList: L.Marker[] = [];
  private isHide: boolean = true;
  private _setSelectedMarker: (marker: ReadByBoundData | null) => void =
    () => {};

  // Constructor
  constructor(
    map: L.Map,
    setSelectedMarker: (marker: ReadByBoundData | null) => void
  ) {
    this._map = map;
    this._setSelectedMarker = setSelectedMarker;
    // Initialize the cluster layer
    this._clusterLayer = L.geoJSON(
      this._markerList.map((marker) => this._parseMarkerToGeoJSON(marker)),
      {
        pointToLayer: this._createClusterIcon.bind(this),
      }
    ).addTo(this._map);
    this._map.on("moveend", () => {
      this.updateCluster(this._markerList);
    });
  }

  // Method to create a cluster icon
  private _createClusterIcon(
    feature: GeoJSON.Feature<GeoJSON.Point, any>,
    latlng: L.LatLng
  ): L.Marker {
    // If the feature is not a cluster, return a simple marker.
    if (!feature.properties.cluster) {
      const { company_address, company_name } = parsePopupContent(
        feature.properties.popupContent
      );
      return makeMarker(
        {
          lat: feature.geometry.coordinates[1],
          lng: feature.geometry.coordinates[0],
          popupContent: feature.properties.popupContent,
          company_name: company_name,
          company_address: company_address,
          id: 0,
        },
        this._map,
        this._setSelectedMarker
      );
    }
    // If the feature is a cluster, return a divIcon with the number of markers.
    const count = feature.properties.point_count;
    const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
    const icon = L.divIcon({
      html: `<div><span>${count}</span></div>`,
      className: `marker-cluster marker-cluster-${size}`,
      iconSize: L.point(40, 40),
    });
    const clusterMarker = L.marker(latlng, { icon });
    clusterMarker.on("click", () => {
      console.log("clusterMarker clicked");
      // Zoom on the cluster
      this._map.flyTo(
        clusterMarker.getLatLng(),
        this._map.getZoom() + 3 >= maxZoom ? maxZoom : this._map.getZoom() + 3
      );
    });

    return clusterMarker;
  }

  // Method to parse marker to GeoJSON
  private _parseMarkerToGeoJSON(marker: L.Marker) {
    let parsed = marker.toGeoJSON();
    parsed.properties.popupContent = marker.getPopup()?.getContent();
    return parsed;
  }

  updateCluster(markerList: L.Marker[]) {
    this._markerList = markerList;
    const bounds = this._map.getBounds();
    superClusterIndex.load(
      markerList.map((marker) => this._parseMarkerToGeoJSON(marker))
    );
    const clusterArray = superClusterIndex.getClusters(
      [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ],
      this._map.getZoom()
    );
    this._clusterLayer.clearLayers();
    clusterArray.forEach((cluster) => {
      this._clusterLayer.addData(cluster);
    });
  }

  showCluster() {
    this._clusterLayer.addTo(this._map);
    this.isHide = false;
  }

  hideCluster() {
    this._clusterLayer.remove();
    this.isHide = true;
  }

  get isHideCluster() {
    return this.isHide;
  }
}
