import "../../styles/leafletMap.css";
import "../../styles/cluster.css";
import {
  BASE_URL,
  Prefecture,
  prefectureCords,
  showDetailZoom,
} from "../../configs/configs";
import { useEffect, useMemo, useRef } from "react";
import {
  MarkerProps,
  PrefectureCountProps,
  parseReadByBoundDataListToMarkerPropsList,
  usePrefectureCountMarkers,
} from "../../hooks/markers";
import { Cluster } from "../../hooks/clusters";
import {
  useReadByBound,
  useGetDataFromDB,
  PostgisAction,
  read_prefecture_count_handler,
  ReadByBoundData,
  ReadByBoundParam,
} from "../../hooks/postgis";
import { Map } from "leaflet";
import { isMobile, setMobileClass } from "../../utils/utils";
import classnames from "classnames";
import _ from "lodash";

type LeafletMapProps = {
  map: Map | null;
  isMoved: number;
  markerList: L.Marker[];
  clusterRef: React.MutableRefObject<Cluster | null>;
  isSideBarOpen: boolean;
  updatedDate: string;
  updateMarkers: (newMarkers: MarkerProps[], toMap?: boolean) => void;
  toggleSideBar: () => void;
  setSelectedMarker: (marker: ReadByBoundData | null) => void;
  fetchDate: () => void;
  total: number;
};

const LeafletMap: React.FC<LeafletMapProps> = ({
  map,
  isMoved,
  markerList,
  updateMarkers,
  clusterRef,
  toggleSideBar,
  isSideBarOpen,
  setSelectedMarker,
  updatedDate,
  fetchDate,
  total,
}) => {
  // Get company data from the server.
  const { data, loading, error, fetchData } = useReadByBound(
    `${BASE_URL}/read_by_bound`
  );
  // Do not update the data if the params are the same.
  const lastParams = useRef<ReadByBoundParam | null>(null);
  const refreshData = () => {
    const bounds = map?.getBounds();
    if (!bounds) {
      return;
    }
    const params = {
      southWest: {
        lat: bounds?.getSouthWest().lat,
        lng: bounds?.getSouthWest().lng,
      },
      northEast: {
        lat: bounds?.getNorthEast().lat,
        lng: bounds?.getNorthEast().lng,
      },
      limit: 0,
      offset: 0,
    };
    if (_.isEqual(params, lastParams.current)) {
      return;
    }
    lastParams.current = params;
    fetchData(params);
  };

  const debounceRefreshData = _.debounce(
    () => {
      console.log("Map isMoved. Refresh data.");
      refreshData();
    },
    500,
    { trailing: true }
  );
  // If the map is moved, update the data.
  useEffect(() => {
    // If the zoom is smaller than showDetailZoom, show prefecture count.
    if (map && map.getZoom() < showDetailZoom) {
      if (isPrefectureCountMarkersHide) showPrefectureCountMarkers();
      if (!clusterRef.current?.isHideCluster) clusterRef.current?.hideCluster();
    } else {
      // If the zoom is larger than showDetailZoom, show cluster.
      // Add a delay to prevent the map from being moved too fast.
      debounceRefreshData();
      if (!isPrefectureCountMarkersHide) hidePrefectureCountMarkers();
      if (clusterRef.current?.isHideCluster) clusterRef.current?.showCluster();
    }
  }, [isMoved]);
  // If the data is updated, update the markerList.
  useEffect(() => {
    if (loading || error || !data) {
      return;
    }
    updateMarkers(parseReadByBoundDataListToMarkerPropsList(data), false);
  }, [data]);
  // If the markerList is updated, update the cluster.
  useEffect(() => {
    if (clusterRef.current) {
      clusterRef.current.updateCluster(markerList);
    }
  }, [markerList]);

  // Get prefecture count from the server.
  const { data: prefectureCount, fetchData: fetchPrefectureCount } =
    useGetDataFromDB(
      `${BASE_URL}/read_prefecture_count`,
      PostgisAction.READ_PREFECTURE_COUNT,
      read_prefecture_count_handler
    );

  const {
    prefectureCountMarkers,
    isHide: isPrefectureCountMarkersHide,
    addPrefectureCountMarkers,
    showPrefectureCountMarkers,
    hidePrefectureCountMarkers,
  } = usePrefectureCountMarkers(map);

  // Initialize everything when the map is created.
  useEffect(() => {
    if (map) {
      clusterRef.current = new Cluster(map, setSelectedMarker);
      fetchPrefectureCount();
      fetchDate();
    }
  }, [map]);
  const parsePrefectureCount = (prefectureCount: any) => {
    let _prefectureCountList = [] as PrefectureCountProps[];
    // Make PrefectureCountProps[]
    for (const prefecture in Prefecture) {
      const _prefectureCountItem: PrefectureCountProps = {
        name: prefecture as Prefecture,
        lat: prefectureCords.find((item) => item.name === prefecture)?.lat || 0,
        lng: prefectureCords.find((item) => item.name === prefecture)?.lng || 0,
        count: prefectureCount[prefecture] || 0,
      };
      _prefectureCountList.push(_prefectureCountItem);
    }
    return _prefectureCountList;
  };
  useEffect(() => {
    // If the prefectureCount is updated, update the prefectureCountMarkers.
    if (prefectureCount && map) {
      const _prefectureCountList = parsePrefectureCount(prefectureCount);
      addPrefectureCountMarkers(_prefectureCountList);
    }
  }, [prefectureCount]);
  useEffect(() => {
    showPrefectureCountMarkers();
    clusterRef.current?.hideCluster();
  }, [prefectureCountMarkers]);

  // The toggleButtonText would be different depending on the device.
  const toggleButtonText = useMemo(() => {
    if (isMobile()) {
      return isSideBarOpen ? "⬆地図に戻る" : "⬆事業者一覧";
    }
    return isSideBarOpen ? "＜閉じる" : "＞開く";
  }, [isSideBarOpen]);

  return (
    <>
      {/* map */}
      <div id="map"></div>
      {/* notification */}
      {updatedDate && (
        <div
          id="data-time-record"
          className={classnames("data-time-and-total", setMobileClass())}
        >
          当該登録事業者は{updatedDate}
          時点のCCUS事業者登録を行っている法人のみを表示しております。
        </div>
      )}
      {/* total company number
      {total && !isMobile() && (
        <div id="data-total" className="data-time-and-total">
          合計<span style={{ fontWeight: "bold" }}> {total} </span>個業者
        </div>
      )} */}
      {/* sidebar button: hide in mobile version */}
      {!isMobile() && (
        <div
          onClick={toggleSideBar}
          className={
            isMobile()
              ? classnames(
                  "toggle-button",
                  "toggle-button-mobile",
                  isSideBarOpen
                    ? "toggle-button-mobile-open"
                    : "toggle-button-mobile-close"
                )
              : classnames("toggle-button", "toggle-button-pc")
          }
        >
          <p className={isMobile() ? "horizontal-text" : "vertical-text"}>
            {toggleButtonText}
          </p>
        </div>
      )}
    </>
  );
};

export default LeafletMap;
