import { useState } from "react";
import { getPostgisDataUrl } from "../configs/configs";

type ReadByBoundDataTmp = {
  n: string;
  a: string;
  lng: number;
  lat: number;
  id: number;
};
export type ReadByBoundData = {
  company_name: string;
  company_address: string;
  lng: number;
  lat: number;
  id: number;
};
export type ReadByBoundRes = {
  status: string;
  data: {
    companies: ReadByBoundDataTmp[];
    total: number;
  };
};
export type ReadByBoundParam = {
  southWest: {
    lat: number;
    lng: number;
  };
  northEast: {
    lat: number;
    lng: number;
  };
  limit: number;
  offset: number;
};
export type CommonRes = {
  status: string;
  data: any;
};
export enum PostgisAction {
  READ_BY_BOUND = "read_by_bound",
  READ_BY_BOUND_COUNT = "read_by_bound_count",
  READ_UPDATED_AT = "read_updated_at",
  READ_PREFECTURE_COUNT = "read_prefecture_count",
}

export const useReadByBound = (lambdaEndpoint: string = getPostgisDataUrl) => {
  const [data, setData] = useState<ReadByBoundData[] | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const fetchData = async (param: ReadByBoundParam) => {
    try {
      const response = await fetch(lambdaEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "read_by_bound",
          ...param,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = (await response.json()) as ReadByBoundRes;
      if (jsonData.status !== "success") {
        throw new Error("Failed to fetch data");
      } else {
        const _data = jsonData.data.companies.map(
          (item) =>
            ({
              company_name: item.n,
              company_address: item.a,
              lng: item.lng,
              lat: item.lat,
              id: item.id,
            } as ReadByBoundData)
        );
        setData(_data);
        setTotal(jsonData.data.total);
        return { data: _data, total: jsonData.data.total };
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData, total };
};

export const useGetDataFromDB = (
  lambdaEndpoint: string = getPostgisDataUrl,
  action: PostgisAction,
  dataHandler: (data: any) => any
) => {
  const [data, setData] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const fetchData = async (params?: any) => {
    try {
      const response = await fetch(lambdaEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action,
          ...params,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = (await response.json()) as CommonRes;
      if (jsonData.status !== "success") {
        throw new Error("Failed to fetch data");
      } else {
        const adjustedData = dataHandler(jsonData.data);
        setData(adjustedData);
        return adjustedData;
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  const clearData = () => {
    setData("");
  };

  return { data, loading, error, fetchData, clearData };
};

export const read_updated_at_handler = (data: any) => {
  return data;
};

export const read_prefecture_count_handler = (data: any) => {
  return data;
};

export const read_by_bound_count_handler = (data: { total: number }) => {
  return data.total;
};
