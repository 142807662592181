import { Button, Flex, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import "../styles/headerPart.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { BASE_URL, ContactPageLink } from "../configs/configs";
import { isMobile, setMobileClass } from "../utils/utils";
import classNames from "classnames";

interface HeaderPartProps {
  updatedDate: string;
}

const HeaderPart: React.FC<HeaderPartProps> = ({ updatedDate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [registerNumber, setRegisterNumber] = useState(0);
  const getRegisterNumber = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/get_registered_company_number`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      if (jsonData.status !== "success") {
        throw new Error("Failed to fetch data");
      } else {
        setRegisterNumber(jsonData.data.number);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRegisterNumber();
  }, []);

  return (
    <div
      className="header-background"
      style={{
        backgroundImage: isMobile()
          ? "url(/assets/images/Header-BG-mobile.png)"
          : "url(/assets/images/Header-BG.png)",
      }}
    >
      <div className={classNames("header-container", setMobileClass())}>
        {/* Logo */}
        <Flex
          justify="space-between"
          align="center"
          className={classNames("logo-container", setMobileClass())}
        >
          <img
            src="assets/images/CCUS-logo.png"
            alt="CCUS-logo"
            id="ccus-logo"
            className={setMobileClass()}
          />
          <img
            src="assets/images/FIRST-logo.png"
            alt="FIRST-logo"
            id="first-logo"
            className={setMobileClass()}
          />
        </Flex>
        {/* First Line */}
        {isMobile() ? (
          <div style={{ textAlign: "center" }}>
            <div className="header-big-font start header-first-line-mobile">
              CCUS登録済事業者
            </div>
            <div style={{ lineHeight: "50px", marginTop: "10px" }}>
              <span className="header-special-number">
                {registerNumber == 0 ? "" : registerNumber}
              </span>
              <span className="header-special-text">万社突破!</span>
            </div>
            <Flex justify="center">
              <Flex
                align="flex-start"
                style={{
                  flexDirection: "column",
                  width: "fit-content",
                }}
              >
                <div className="header-first-line-mobile">
                  多くの事業者が登録･
                </div>
                <div className="header-first-line-mobile">
                  利用を行っています。
                </div>
              </Flex>
            </Flex>
          </div>
        ) : (
          <Flex justify="center" align="flex-end" style={{ flexWrap: "wrap" }}>
            <div>
              <span className="header-big-font start header-line-height">
                <span className="header-ccus-font header-line-height">
                  CCUS
                </span>
                登録済事業者
              </span>
              <span className="header-special-number header-line-height">
                {registerNumber == 0 ? "" : registerNumber}
              </span>
              <span className="header-special-text header-line-height">
                万社突破!
              </span>
            </div>
            <div>
              <span
                className="header-big-font end header-line-height"
                style={{ letterSpacing: -1 }}
              >
                多くの事業者が登録･利用を行っています。
              </span>
            </div>
          </Flex>
        )}

        {/* Second Line */}
        <p className={classNames("header-second-line", setMobileClass())}>
          申請代行についてお気軽にお問合せください
        </p>
        {/* Third Line: Phone and Email */}
        <Flex
          justify="space-evenly"
          align="center"
          className={isMobile() ? "header-third-line-mobile" : ""}
        >
          <Space
            direction={isMobile() ? "vertical" : "horizontal"}
            size="large"
          >
            <a href="tel:+810487318881">
              <img
                src="assets/images/btn-tel.png"
                alt="btn-tel"
                className={classNames("header-btn-pic", setMobileClass())}
              />
            </a>
            <a href={ContactPageLink} target="_blank">
              <img
                src="assets/images/btn-mail.png"
                alt="btn-mail"
                className={classNames("header-btn-pic", setMobileClass())}
              />
            </a>
          </Space>
        </Flex>
        {/* Fourth Line */}
        <p className={classNames("header-fourth-line", setMobileClass())}>
          ※以下の地図から登録事業者の情報をご覧いただくことが可能です。
        </p>
        <ReadNotificationModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          updatedDate={updatedDate}
        />
      </div>
      {/* Alert Button */}
      <div
        className={classNames("header-alter-btn-container", setMobileClass())}
      >
        <Button
          className={classNames("header-alert-btn", setMobileClass())}
          onClick={() => {
            setModalOpen(true);
          }}
          icon={
            <img
              src="assets/images/alert-icon.png"
              alt="alert-icon"
              id="header-alert-icon"
            />
          }
        >
          ご利用になる前にお読みください
        </Button>
      </div>
    </div>
  );
};

export default HeaderPart;

interface ReadNotificationModalProps {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  updatedDate: string;
}
const ReadNotificationModal: React.FC<ReadNotificationModalProps> = ({
  modalOpen,
  setModalOpen,
  updatedDate,
}) => {
  return (
    <Modal
      title={
        <div className={classNames("modal-title", setMobileClass())}>
          注意事項・免責事項
        </div>
      }
      centered
      closeIcon={<CloseCircleOutlined className="modal-close-icon" />}
      footer={null}
      open={modalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={() => setModalOpen(false)}
      width={isMobile() ? "95vw" : "45vw"}
      wrapClassName={classNames("modal-wrap", setMobileClass())}
    >
      <p className={isMobile() ? "modal-text-mobile" : "modal-text"}>
        本機能の登録事業者さま情報は建設キャリアアップシステムホームページ『登録事業者検索』の
        情報を引用し地図上にマッピングしておりますが、実際の所在地と座標が異なるケースもございますので、
        あらかじめご了承の上ご利用ください。
      </p>
      <p className={isMobile() ? "modal-text-mobile" : "modal-text"}>
        掲載内容につきまして、 {updatedDate}時点の情報を利用しております。
        {updatedDate}
        以降の情報が反映されておりません。あらかじめご了承の上ご利用ください。
      </p>
      <p className={isMobile() ? "modal-text-mobile" : "modal-text"}>
        本機能はURLを予告なしに変更または削除される場合があります。
      </p>
    </Modal>
  );
};
