import { Flex, Space } from "antd";
import "../styles/footerPart.css";
import { ContactPageLink, HomePageLink } from "../configs/configs";
import { isMobile, setMobileClass } from "../utils/utils";
import classNames from "classnames";

interface FooterPartProps {
  sideBarRef: React.RefObject<HTMLDivElement>;
}
const FooterPart: React.FC<FooterPartProps> = ({ sideBarRef }) => {
  return (
    <div
      className={classNames("footer-background", setMobileClass())}
      style={{
        backgroundImage: isMobile()
          ? "url(assets/images/Footer-BG-mobile.png)"
          : "url(assets/images/Footer-BG.png)",
        marginTop: isMobile()
          ? sideBarRef.current
            ? sideBarRef.current.offsetHeight * 0.8
            : 0
          : 0,
      }}
    >
      <div className="footer-container">
        {/* First Line */}
        <p className={classNames("footer-first-line", setMobileClass())}>
          CCUSの登録をご検討の方はまずはお問合せください。
        </p>
        {/* Phone and Mail */}
        <Flex
          justify="space-evenly"
          align="center"
          className={isMobile() ? "header-third-line-mobile" : ""}
        >
          <Space
            direction={isMobile() ? "vertical" : "horizontal"}
            size="large"
          >
            <a href="tel:+810487318881">
              <img
                src="assets/images/btn-tel.png"
                alt="btn-tel"
                className={classNames("header-btn-pic", setMobileClass())}
              />
            </a>
            <a href={ContactPageLink} target="_blank">
              <img
                src="assets/images/btn-mail.png"
                alt="btn-mail"
                className={classNames("header-btn-pic", setMobileClass())}
              />
            </a>
          </Space>
        </Flex>
        {/* Company Info */}
        {isMobile() ? (
          <div className="footer-company-info-container-mobile">
            <p className={classNames("footer-company-name", "mobile")}>
              運営元：株式会社FIRST
            </p>
            <p className="footer-company-address">
              埼玉県春日部市南1丁目1番地７号
              <br />
              電話：048-731-8881
            </p>
            <p className={classNames("footer-info", setMobileClass())}>
              建設キャリアアップシステムホームページ
              <br />
              『登録事業者検索』
              <br />
              の情報を引用しております。
            </p>
            <div
              className="footer-company-homepage"
              onClick={() => {
                window.open(HomePageLink, "_blank");
              }}
            >
              <span className="footer-company-homepage">
                株式会社FIRSTのホームページ
              </span>
              <img
                src="assets/images/new-window-icon.png"
                alt="new-window-icon"
                id="new-window-icon"
              />
            </div>
          </div>
        ) : (
          <>
            <Flex justify="space-between">
              <p className="footer-company-name">運営元：株式会社FIRST</p>
              <p className="footer-info">
                建設キャリアアップシステムホームページ『登録事業者検索』の情報を引用しております。
              </p>
            </Flex>
            <Flex justify="space-between">
              <p className="footer-company-address">
                埼玉県春日部市南1丁目1番地７号　電話：048-731-8881
              </p>
              <div
                className="footer-company-homepage"
                onClick={() => {
                  window.open(HomePageLink, "_blank");
                }}
              >
                <span className="footer-company-homepage">
                  株式会社FIRSTのホームページ
                </span>
                <img
                  src="assets/images/new-window-icon.png"
                  alt="new-window-icon"
                  id="new-window-icon"
                />
              </div>
            </Flex>
          </>
        )}
      </div>
    </div>
  );
};

export default FooterPart;
