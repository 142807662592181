import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import VirtualList from "rc-virtual-list";
import { Card, Empty, List } from "antd";
import {
  ReadByBoundData,
  ReadByBoundParam,
  useReadByBound,
} from "../../hooks/postgis";
import { Map } from "leaflet";
import {
  MarkerProps,
  flyAndOpenMarkerPopup,
  parseReadByBoundDataListToMarkerPropsList,
} from "../../hooks/markers";
import { isMobile, parsePopupContent } from "../../utils/utils";
import { BASE_URL } from "../../configs/configs";
import classnames from "classnames";

interface VListProps {
  map: Map | null;
  height?: number;
  markerList: L.Marker[];
  isMoved: number;
  toggleSideBarMobile: () => void;
  updateMarkers: (newMarkers: MarkerProps[], toMap?: boolean) => void;
  selectedMarker: ReadByBoundData | null;
  setSelectedMarker: (marker: ReadByBoundData | null) => void;
  setTotal: (total: number) => void;
}
const pageSize = 20;

const VList: React.FC<VListProps> = ({
  map,
  height,
  markerList,
  isMoved,
  toggleSideBarMobile,
  updateMarkers,
  selectedMarker,
  setSelectedMarker,
  setTotal,
}) => {
  // Height of the container
  const ContainerHeight =
    (height ?? 500) - (isMobile() ? 10 : 55) - (selectedMarker ? 170 : 0);

  // Get data from the database.
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<ReadByBoundData[]>([]);
  const [isEndPage, setIsEndPage] = useState<boolean>(false);
  const { loading, fetchData } = useReadByBound(`${BASE_URL}/read_by_bound`);

  // If the map is moved, update the list.
  const lastParams = useRef<ReadByBoundParam | null>(null);
  const appendData = async (init: boolean = false) => {
    if (!map) return;
    const bounds = map?.getBounds();
    const params = {
      southWest: {
        lat: bounds?.getSouthWest().lat,
        lng: bounds?.getSouthWest().lng,
      },
      northEast: {
        lat: bounds?.getNorthEast().lat,
        lng: bounds?.getNorthEast().lng,
      },
      limit: pageSize,
      offset: init ? 0 : page * pageSize,
    };
    if (_.isEqual(params, lastParams.current)) {
      return;
    }
    lastParams.current = params;
    const res = await fetchData(params);
    const newData = res?.data;
    const total = res?.total;
    if (!newData) return;
    if (!total) {
      setData([]);
      setPage(0);
      setTotal(0);
      return;
    }
    setTotal(total);
    if (pageSize * (init ? 1 : page) >= total) {
      setIsEndPage(true);
    } else {
      setIsEndPage(false);
    }
    if (init) {
      setData(newData);
      updateMarkers(parseReadByBoundDataListToMarkerPropsList(newData), false);
      setPage(1);
      return;
    } else {
      const newDataFiltered = newData;
      setData((prevData) => [...prevData, ...newDataFiltered]);
      updateMarkers(
        parseReadByBoundDataListToMarkerPropsList(newDataFiltered),
        false
      );
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Initialize everything when the map is created.
  useEffect(() => {
    appendData(true);
  }, [map, isMoved]);

  // Load more data when the user scrolls to the bottom.
  const debounceAppendData = _.debounce(
    () => {
      console.log("VList: onScroll: appendData");
      appendData();
    },
    500,
    { leading: true }
  );
  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 0.5 <=
        ContainerHeight &&
      !isEndPage
    ) {
      debounceAppendData();
    }
  };

  // Fly to the marker when the user clicks the card.
  const flyToMarker = (company: ReadByBoundData) => {
    if (!map) return;
    const marker = markerList.find((marker) => {
      const { company_address, company_name } = parsePopupContent(
        marker.getPopup()?.getContent()?.toString() ?? ""
      );
      return (
        company_address?.trim() === company.company_address?.trim() &&
        company_name?.trim() === company.company_name?.trim()
      );
    });
    if (!marker) return;
    flyAndOpenMarkerPopup(marker, map, true);
  };

  return (
    <>
      {selectedMarker && (
        <>
          <Card
            style={{ marginTop: 10 }}
            headStyle={{ minHeight: 50 }}
            title="選択中の業者"
            className={classnames(
              isMobile() ? "sidebar-card-mobile" : "sidebar-card"
            )}
            bodyStyle={{ padding: 15 }}
            onClick={() => {
              flyToMarker(selectedMarker);
              toggleSideBarMobile();
            }}
          >
            <div className="sidebar-card-title">
              {selectedMarker.company_name?.trim()}
            </div>
            <div className="sidebar-card-body">
              {selectedMarker.company_address?.trim()}
            </div>
          </Card>
          <hr />
        </>
      )}
      {data.length === 0 ? (
        <Card
          className={isMobile() ? "sidebar-card-mobile" : "sidebar-card"}
          bodyStyle={{ padding: 15 }}
          loading={loading}
        >
          <Empty description={"該当なし"} />
        </Card>
      ) : (
        <List split={false} loading={loading}>
          <VirtualList
            key={isMoved ? 0 : 1}
            data={data}
            height={ContainerHeight}
            itemHeight={47}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item: ReadByBoundData) => (
              <List.Item key={item.id} style={{ padding: "5px 0px" }}>
                <Card
                  className={classnames(
                    isMobile() ? "sidebar-card-mobile" : "sidebar-card"
                  )}
                  bodyStyle={{ padding: 15 }}
                  onClick={() => {
                    setSelectedMarker(item);
                    flyToMarker(item);
                    toggleSideBarMobile();
                  }}
                >
                  <div className="sidebar-card-title">
                    {item.company_name?.trim()}
                  </div>
                  <div className="sidebar-card-body">
                    {item.company_address?.trim()}
                  </div>
                </Card>
              </List.Item>
            )}
          </VirtualList>
        </List>
      )}
    </>
  );
};

export default VList;
