import { useRef } from "react";
import "../../styles/sidebar.css";
import { Map } from "leaflet";
import VList from "./VList";
import { formatNumber, isMobile, setMobileClass } from "../../utils/utils";
import { MarkerProps } from "../../hooks/markers";
import { ReadByBoundData } from "../../hooks/postgis";
import { Flex } from "antd";

type SideBarProps = {
  isMoved: number;
  map: Map | null;
  markerList: L.Marker[];
  toggleSideBarMobile: () => void;
  updateMarkers: (newMarkers: MarkerProps[], toMap?: boolean) => void;
  selectedMarker: ReadByBoundData | null;
  setSelectedMarker: (marker: ReadByBoundData | null) => void;
  total: number;
  setTotal: (total: number) => void;
  isSideBarOpen: boolean;
};

const SideBar: React.FC<SideBarProps> = ({
  isMoved,
  map,
  markerList,
  toggleSideBarMobile,
  updateMarkers,
  selectedMarker,
  setSelectedMarker,
  total,
  setTotal,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div id="sidebar" className={setMobileClass()} ref={sidebarRef}>
        <Flex style={{ flexDirection: "column" }} align="center">
          <div className="sidebar-title">法人登録事業者リスト</div>
          <div className="side-bar-total">表示{formatNumber(total)}社</div>
        </Flex>
        <VList
          toggleSideBarMobile={toggleSideBarMobile}
          updateMarkers={updateMarkers}
          map={map}
          height={
            sidebarRef.current
              ? sidebarRef.current?.clientHeight - (isMobile() ? 70 : 0)
              : undefined
          }
          markerList={markerList}
          isMoved={isMoved}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          setTotal={setTotal}
        />
      </div>
    </>
  );
};

export default SideBar;
