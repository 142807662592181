// Check if the user is on a mobile device
export function isMobile() {
  // Use width to check if the user is on a mobile device
  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const mobile = width < 768;
  return mobile;
}

// Return "mobile" if the user is on a mobile device
export function setMobileClass() {
  return isMobile() ? "mobile" : "";
}

export function parsePopupContent(popupContent: string) {
  // Get companyName and companyAddress from
  // `<div>
  //     <div class="popup-title">${companyName}</div>
  //     <hr class="popup-divider" />
  //     <div class="popup-body">${companyAddress}</div>
  //   </div>`
  try {
    const company_name = popupContent
      .split('<div class="popup-title">')[1]
      .split("</div>")[0];
    const company_address = popupContent
      .split('<div class="popup-body">')[1]
      .split("</div>")[0];
    return { company_name, company_address };
  } catch (error) {
    console.log(error);
    return { company_name: "", company_address: "" };
  }
}

export function formatNumber(number: number | string) {
  // Format number to have commas
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
