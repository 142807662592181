import "./styles/main.css";
import { BASE_URL } from "./configs/configs";
import {
  PostgisAction,
  read_updated_at_handler,
  useGetDataFromDB,
} from "./hooks/postgis";
import HeaderPart from "./components/HeaderPart";
import MyMap from "./components/MyMap";
import FooterPart from "./components/FooterPart";
import { useRef } from "react";
import { isMobile } from "./utils/utils";

function App() {
  // Fix the problem that the click event is not fired on iOS.
  document.addEventListener("touchstart", function (e) {
    if (e.target instanceof HTMLElement) {
      if (e.target.tagName !== "SPAN" && e.target.tagName !== "IMG") return;
      e.target.click();
    }
  });
  // Get updated_at from the server. -> this will be shared by HeaderPart and LeafletMap.
  const { data: updatedDate, fetchData: fetchDate } = useGetDataFromDB(
    `${BASE_URL}/read_updated_at`,
    PostgisAction.READ_UPDATED_AT,
    read_updated_at_handler
  );
  if (isMobile()) {
    // Add overflow: scroll; to body and html
    document.body.style.overflow = "scroll";
    document.documentElement.style.overflow = "scroll";
  }

  // SideBar Reference -> this will be shared by FooterPart and SideBar.
  const sideBarRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <HeaderPart updatedDate={updatedDate} />
      <MyMap
        updatedDate={updatedDate}
        fetchDate={fetchDate}
        sideBarRef={sideBarRef}
      />
      <FooterPart sideBarRef={sideBarRef} />
    </>
  );
}

export default App;
