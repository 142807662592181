import { useRef, useState } from "react";
import {
  Prefecture,
  companyIcon,
  companyIconLargeSize,
  maxZoom,
} from "../configs/configs";
import L from "leaflet";
import { ReadByBoundData } from "./postgis";
import { isMobile } from "../utils/utils";

export type MarkerProps = {
  lat: number;
  lng: number;
  popupContent: string;
  company_name: string;
  company_address: string;
  id: number;
};

export function useMarkers(
  map: L.Map | null,
  setSelectedMarker: (marker: ReadByBoundData | null) => void
): {
  markerList: L.Marker[];
  isHide: boolean;
  deleteMarker: (marker: L.Marker) => void;
  updateMarkers: (newMarkers: MarkerProps[], toMap?: boolean) => void;
  hideMarkers: () => void;
  showMarkers: () => void;
} {
  // Initialize the marker list, all markers will be stored in this list
  const [markerList, setMarkerList] = useState<L.Marker[]>([]);
  const markerMapRef = useRef<Set<number>>(new Set());
  const [isHide, setIsHide] = useState<boolean>(true);

  // Add a marker to the map
  function addMarker(markerProps: MarkerProps, toMap = true): L.Marker | null {
    if (!map || markerMapRef.current.has(markerProps.id)) return null;
    const marker = makeMarker(markerProps, map, setSelectedMarker);
    // Add the marker to the map if toMap is true, otherwise just add it to the marker list
    if (toMap) {
      marker.addTo(map);
    }
    markerMapRef.current.add(markerProps.id);
    return marker;
  }

  // Update new markers into marker list
  function updateMarkers(newMarkers: MarkerProps[], toMap = true) {
    let newMarkerList: L.Marker[] = [];
    newMarkers.forEach((markerProps) => {
      const newMarker = addMarker(markerProps, toMap);
      if (newMarker) {
        newMarkerList.push(newMarker);
      }
    });
    setMarkerList((prevMarkerList) => [...prevMarkerList, ...newMarkerList]);
  }

  // Delete a marker from the map
  function deleteMarker(marker: L.Marker) {
    marker.remove();
    setMarkerList((prevMarkerList) =>
      prevMarkerList.filter((prevMarker) => prevMarker !== marker)
    );
  }

  // Hide all markers
  function hideMarkers() {
    markerList.forEach((marker) => {
      marker.remove();
    });
    setIsHide(true);
  }

  // Show all markers
  function showMarkers() {
    if (!map) return;
    markerList.forEach((marker) => {
      marker.addTo(map);
    });
    setIsHide(false);
  }

  return {
    markerList,
    isHide,
    deleteMarker,
    updateMarkers,
    hideMarkers,
    showMarkers,
  };
}

export interface PrefectureCountProps {
  name: Prefecture;
  lat: number;
  lng: number;
  count: number;
}
export function usePrefectureCountMarkers(map: L.Map | null): {
  prefectureCountMarkers: L.Marker[];
  isHide: boolean;
  addPrefectureCountMarkers: (prefectureCount: PrefectureCountProps[]) => void;
  showPrefectureCountMarkers: () => void;
  hidePrefectureCountMarkers: () => void;
} {
  const [prefectureCountMarkers, setPrefectureCountMarkers] = useState<
    L.Marker[]
  >([]);
  const [isHide, setIsHide] = useState<boolean>(false);

  const addPrefectureCountMarkers = (
    prefectureCount: PrefectureCountProps[]
  ) => {
    if (!map) return;
    prefectureCount.forEach((prefecture) => {
      // If the feature is a cluster, return a divIcon with the number of markers.
      const count = prefecture.count;
      const size = count <= 1000 ? "small" : "large";
      const icon = L.divIcon({
        html: `<div><span>${count}</span></div>`,
        className: `marker-prefecture marker-prefecture-${size}`,
        iconSize: L.point(40, 40),
      });
      const latlng = new L.LatLng(prefecture.lat, prefecture.lng);
      const prefectureMarker = L.marker(latlng, { icon });
      prefectureMarker.on("click", () => {
        // Zoom on the cluster
        map.flyTo(prefectureMarker.getLatLng(), map.getZoom() + 5);
      });
      setPrefectureCountMarkers((prev) => [...prev, prefectureMarker]);
    });
  };

  const showPrefectureCountMarkers = () => {
    if (!map) return;
    prefectureCountMarkers.forEach((marker) => {
      marker.addTo(map);
    });
    setIsHide(false);
  };

  const hidePrefectureCountMarkers = () => {
    if (!map) return;
    prefectureCountMarkers.forEach((marker) => {
      marker.remove();
    });
    setIsHide(true);
  };

  return {
    prefectureCountMarkers,
    isHide,
    addPrefectureCountMarkers,
    showPrefectureCountMarkers,
    hidePrefectureCountMarkers,
  };
}

// Create a marker with properties that are necessary
export function makeMarker(
  markerProps: MarkerProps,
  map: L.Map,
  setSelectedMarker: (marker: ReadByBoundData | null) => void
) {
  const { lat, lng, popupContent } = markerProps;
  const marker = L.marker([lat, lng], {
    icon: companyIcon,
  });
  marker.bindPopup(popupContent, { autoPan: false });
  // Focus on the marker when clicked
  marker.on("click", () => {
    setSelectedMarker({
      company_name: markerProps.company_name,
      company_address: markerProps.company_address,
      id: markerProps.id,
      lat: marker.getLatLng().lat,
      lng: marker.getLatLng().lng,
    });
    try {
      flyAndOpenMarkerPopup(marker, map);
    } catch (error) {
      map.panTo([lat, lng]);
    }
  });
  // Change the icon size when it is hovered
  marker.on("mouseover", () => {
    if (marker.isPopupOpen()) return;
    marker.setIcon(companyIconLargeSize);
  });
  // Change the icon size when it is not hovered
  marker.on("mouseout", () => {
    if (marker.isPopupOpen()) return;
    marker.setIcon(companyIcon);
  });
  // Change the icon size when the popup is opened
  marker.on("popupopen", () => {
    marker.setIcon(companyIconLargeSize);
  });
  return marker;
}

export function createPopupElement(
  companyName: string,
  companyAddress: string
) {
  return `<div>
      <div class="popup-title">${companyName}</div>
      <hr class="popup-divider" />
      <div class="popup-body">${companyAddress}</div>
    </div>`;
}

export function flyAndOpenMarkerPopup(
  marker: L.Marker,
  map: L.Map,
  fly = false
) {
  const _popupElement = marker.getPopup();
  if (!_popupElement) return;
  marker.unbindPopup();
  if (fly) map.flyTo(marker.getLatLng(), maxZoom);
  else map.panTo(marker.getLatLng());
  setTimeout(() => {
    const content = _popupElement.getContent();
    if (!content) return;
    map.openPopup(content.toString(), marker.getLatLng(), {
      offset: L.point(0, -30),
    });
    marker.bindPopup(content);
    if (isMobile()) {
      const elmt = document.getElementById("data-time-record");
      if (elmt) {
        elmt.scrollIntoView({ block: "start" });
      }
    }
  }, 300);
}

export function parseReadByBoundDataListToMarkerPropsList(
  data: ReadByBoundData[]
): MarkerProps[] {
  return data.map((company) => {
    const popupContent = createPopupElement(
      company.company_name,
      company.company_address
    );
    return {
      lat: company.lat,
      lng: company.lng,
      popupContent,
      company_address: company.company_address,
      company_name: company.company_name,
      id: company.id,
    };
  });
}
