import { useEffect, useState } from "react";
import { maxZoom, minZoom } from "../configs/configs";
import L from "leaflet";
import { isMobile } from "../utils/utils";

const useCreateMap = (
  mapContainerId: string,
  options?: L.MapOptions
): { map: L.Map | null; isMoved: number } => {
  const [map, setMap] = useState<L.Map | null>(null);

  const [isMoved, setIsMoved] = useState(0);
  const [_options] = useState<L.MapOptions | undefined>(options); // use useState to avoid useEffect infinite loop

  useEffect(() => {
    const mapContainer = L.DomUtil.get(mapContainerId);
    if (mapContainer && !mapContainer.classList.contains("leaflet-container")) {
      const _map = L.map(mapContainer, _options);
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        // maxZoom,
        // minZoom,
        attribution: `${
          isMobile() ? "<br>" : ""
        }&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
      }).addTo(_map);
      _map.on("moveend", () => {
        setIsMoved((prevIsMoved) => prevIsMoved + 1);
      });
      setMap(_map);
      L.control.zoom({ position: "bottomright" }).addTo(_map);
    }
  }, [mapContainerId, _options]);

  return { map, isMoved };
};

export default useCreateMap;
