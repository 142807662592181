import L from "leaflet";

// Larger number means more zoomed in, for example 0 is the whole world
export const [initZoom, minZoom, maxZoom, showDetailZoom] = [6, 6, 17, 10];
// Tokyo
export const tokyoLatLng = { lat: 35.698374429069425, lng: 139.77304645124707 };
export const initCord = new L.LatLng(tokyoLatLng.lat, tokyoLatLng.lng);
export const initCordMobile = new L.LatLng(
  37.24742740413873,
  137.5489907553115
);
export const companyIcon = L.icon({
  iconUrl: "assets/images/marker.png",
  iconSize: [50, 50],
  iconAnchor: [25, 50],
  popupAnchor: [0, -30],
});
export const companyIconLargeSize = L.icon({
  iconUrl: "assets/images/marker-large.png",
  iconSize: [60, 60],
  iconAnchor: [30, 60],
  popupAnchor: [0, -30],
});
export const getPostgisDataUrl =
  "https://odydp6g4t4x44vb2qg5dlarqla0zeomr.lambda-url.ap-northeast-1.on.aws/";
export const BASE_URL = process.env.REACT_APP_URL_API;

export enum Prefecture {
  "北海道" = "北海道",
  "青森県" = "青森県",
  "岩手県" = "岩手県",
  "宮城県" = "宮城県",
  "秋田県" = "秋田県",
  "山形県" = "山形県",
  "福島県" = "福島県",
  "茨城県" = "茨城県",
  "栃木県" = "栃木県",
  "群馬県" = "群馬県",
  "埼玉県" = "埼玉県",
  "千葉県" = "千葉県",
  "東京都" = "東京都",
  "神奈川県" = "神奈川県",
  "新潟県" = "新潟県",
  "富山県" = "富山県",
  "石川県" = "石川県",
  "福井県" = "福井県",
  "山梨県" = "山梨県",
  "長野県" = "長野県",
  "岐阜県" = "岐阜県",
  "静岡県" = "静岡県",
  "愛知県" = "愛知県",
  "三重県" = "三重県",
  "滋賀県" = "滋賀県",
  "京都府" = "京都府",
  "大阪府" = "大阪府",
  "兵庫県" = "兵庫県",
  "奈良県" = "奈良県",
  "和歌山県" = "和歌山県",
  "鳥取県" = "鳥取県",
  "島根県" = "島根県",
  "岡山県" = "岡山県",
  "広島県" = "広島県",
  "山口県" = "山口県",
  "徳島県" = "徳島県",
  "香川県" = "香川県",
  "愛媛県" = "愛媛県",
  "高知県" = "高知県",
  "福岡県" = "福岡県",
  "佐賀県" = "佐賀県",
  "長崎県" = "長崎県",
  "熊本県" = "熊本県",
  "大分県" = "大分県",
  "宮崎県" = "宮崎県",
  "鹿児島県" = "鹿児島県",
  "沖縄県" = "沖縄県",
}

// Set the center city's cords for each prefecture
export const prefectureCords = [
  { name: Prefecture.北海道, lat: 43.06417, lng: 141.34694 },
  { name: Prefecture.青森県, lat: 40.82444, lng: 140.74 },
  { name: Prefecture.岩手県, lat: 39.70361, lng: 141.1525 },
  { name: Prefecture.宮城県, lat: 38.26889, lng: 140.87194 },
  { name: Prefecture.秋田県, lat: 39.71861, lng: 140.1025 },
  { name: Prefecture.山形県, lat: 38.24056, lng: 140.36333 },
  { name: Prefecture.福島県, lat: 37.75, lng: 140.46778 },
  { name: Prefecture.茨城県, lat: 36.34139, lng: 140.44667 },
  { name: Prefecture.栃木県, lat: 36.56583, lng: 139.88361 },
  { name: Prefecture.群馬県, lat: 36.39111, lng: 139.06083 },
  { name: Prefecture.埼玉県, lat: 35.85694, lng: 139.64889 },
  { name: Prefecture.千葉県, lat: 35.60472, lng: 140.12333 },
  { name: Prefecture.東京都, lat: 35.68944, lng: 139.69167 },
  { name: Prefecture.神奈川県, lat: 35.44778, lng: 139.6425 },
  { name: Prefecture.新潟県, lat: 37.90222, lng: 139.02361 },
  { name: Prefecture.富山県, lat: 36.69528, lng: 137.21139 },
  { name: Prefecture.石川県, lat: 36.59444, lng: 136.62556 },
  { name: Prefecture.福井県, lat: 36.06528, lng: 136.22194 },
  { name: Prefecture.山梨県, lat: 35.66389, lng: 138.56833 },
  { name: Prefecture.長野県, lat: 36.65139, lng: 138.18111 },
  { name: Prefecture.岐阜県, lat: 35.39111, lng: 136.72222 },
  { name: Prefecture.静岡県, lat: 34.97694, lng: 138.38306 },
  { name: Prefecture.愛知県, lat: 35.18028, lng: 136.90667 },
  { name: Prefecture.三重県, lat: 34.73028, lng: 136.50861 },
  { name: Prefecture.滋賀県, lat: 35.00444, lng: 135.86833 },
  { name: Prefecture.京都府, lat: 35.02139, lng: 135.75556 },
  { name: Prefecture.大阪府, lat: 34.68639, lng: 135.52 },
  { name: Prefecture.兵庫県, lat: 34.69139, lng: 135.18306 },
  { name: Prefecture.奈良県, lat: 34.68528, lng: 135.83278 },
  { name: Prefecture.和歌山県, lat: 34.22611, lng: 135.1675 },
  { name: Prefecture.鳥取県, lat: 35.50361, lng: 134.23833 },
  { name: Prefecture.島根県, lat: 35.47222, lng: 133.05056 },
  { name: Prefecture.岡山県, lat: 34.66167, lng: 133.935 },
  { name: Prefecture.広島県, lat: 34.39639, lng: 132.45944 },
  { name: Prefecture.山口県, lat: 34.18583, lng: 131.47139 },
  { name: Prefecture.徳島県, lat: 34.06583, lng: 134.55944 },
  { name: Prefecture.香川県, lat: 34.34028, lng: 134.04333 },
  { name: Prefecture.愛媛県, lat: 33.84167, lng: 132.76611 },
  { name: Prefecture.高知県, lat: 33.55972, lng: 133.53111 },
  { name: Prefecture.福岡県, lat: 33.60639, lng: 130.41806 },
  { name: Prefecture.佐賀県, lat: 33.24944, lng: 130.29889 },
  { name: Prefecture.長崎県, lat: 32.74472, lng: 129.87361 },
  { name: Prefecture.熊本県, lat: 32.78972, lng: 130.74167 },
  { name: Prefecture.大分県, lat: 33.23806, lng: 131.6125 },
  { name: Prefecture.宮崎県, lat: 31.91111, lng: 131.42389 },
  { name: Prefecture.鹿児島県, lat: 31.56028, lng: 130.55806 },
  { name: Prefecture.沖縄県, lat: 26.2125, lng: 127.68111 },
];

export const ContactPageLink = "https://ccus.online/#contact";
export const HomePageLink = "https://first-corporation.co.jp/";
